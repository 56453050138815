<script setup>
import {useForm} from "@inertiajs/vue3";
import {notify} from "notiwind";
import {computed, ref, watch} from "vue";

const props = defineProps({
    company: {
        type: String,
        required: true,
    },
    email: {
        type: String,
        required: false,
        default: null
    },
    classesInput: {
        type: String,
        required: false,
        default: ''
    },
    classesButton: {
        type: String,
        required: false,
        default: ''
    }
})

const isFocused = ref(false),
    isProcessing = ref(false),
    isSent = ref(false),
    secondsTimer = ref(15);

    const form = useForm({
    email: null,
});

const isDisabled = computed(() => {
    return props.company === null || props.company === '' || props.company === undefined
});

const sendMagicLink = () => {
    form.post(route('send-magic-link', props.company), {
        onBefore: () => {
            isProcessing.value = true;
        },
        onSuccess: () => {
            notify({
                group: "general",
                title: 'Correo enviado',
                text: 'Si el correo proporcionado tiene una cuenta activa, te llegará el enlace para iniciar sesión.'
            }, 4000)
            isDisabled.value = true;
            isSent.value = true;
            isProcessing.value = false;
            setCountdown()
            form.reset();
        },
        onError: () => {
            notify({
                group: "general",
                title: 'Error al enviar correo',
                text: 'Ocurrió un error al enviar el correo, por favor intenta de nuevo.'
            }, 4000)
            isProcessing.value = false;
        },
    });
};

watch(() => props.email, (newValue) => {
    form.email = newValue;
});

const setCountdown = () => {
    const countdown = setInterval(() => {
        if (secondsTimer.value <= 0) {
            isSent.value = false;
            secondsTimer.value = 15;
            clearInterval(countdown);
        } else {
            secondsTimer.value--;
        }
    }, 1000);
}
</script>

<template>
    <form @submit.prevent="sendMagicLink">
        <input
            v-model="form.email"
            :class="[classesInput, 'block w-full border-0 py-2 text-gray-900 shadow-sm ring-2 ring-inset placeholder:text-gray-400 focus:ring-[3px] focus:ring-outset sm:text-sm sm:leading-6']"
            type="email" id="email"
            @focus="isFocused = true"
            @blur="isFocused = false"
            placeholder="ejemplo@gmail.com"
            autocomplete="email"
            required
        />
        <button type="submit"
                :class="[classesButton, 'flex gap-3 items-center justify-center w-full py-3 mt-5 disabled:bg-gray-400 disabled:text-white disabled:cursor-not-allowed']"
                :disabled="isDisabled || isProcessing || isSent">
            <slot v-if="!isSent"></slot>
            <span v-else>
                Vuelve a intentar en {{ secondsTimer }} segundos
            </span>
            <svg v-if="isProcessing" class="animate-spin  h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
            </svg>
        </button>
    </form>
</template>

<style scoped>

</style>
